import { Container } from '@ui/components/Container'
import clsx from 'clsx'
import { InstantCashDisclosure } from '@ui/components/Disclosure'

type Props = {
  className?: string
  children?: React.ReactNode
}

export const MainBlockTrackYourSpending = ({ className, children }: Props) => {
  return (
    <div id="budgeting" className={clsx('pt-20', className)}>
      <Container>
        <div className="flex flex-col gap-8 lg:flex-row">
          <div className="flex flex-col justify-center gap-4 pb-4 lg:w-1/2 lg:pb-20">
            <h2 className="font-montserrat h2">
              Track your spending and boost your savings
            </h2>
            <p className="body-hero">
              Take control of your finances with detailed insights into your
              spending habits. Our budgeting tools help you monitor your
              expenses month over month, categorize your spending, and identify
              opportunities to save more. Whether it's cutting down on
              unnecessary costs or setting up personalized budgets, you'll have
              everything you need to help boost your savings and achieve your
              financial goals.
            </p>
          </div>
          <div className="flex-auto lg:w-1/2">{children}</div>
        </div>
      </Container>
    </div>
  )
}

export const MainBlockPersonalFinance = ({ className }: Props) => {
  return (
    <div
      id="personal-finance"
      className={clsx('bg-blueberry-1000 py-20 text-white', className)}
    >
      <Container>
        <div className="flex flex-col gap-8">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
            <div className="text-dolphin-1000 flex flex-col gap-2 rounded-lg bg-white p-8">
              <h3 className="h2 text-blueberry-700">Personal Finance</h3>
              <h4 className="h4">$3.99/month</h4>
              <ul className="body-2 list-disc pl-8">
                <li>Access* to ATM Instant Cash™</li>
                <li>Spending & cashflow insights</li>
                <li>Budgeting</li>
                <li>Subscription & bill tracking</li>
                <li>Low balance alerts</li>
                <li>1 monitored account</li>
              </ul>
            </div>
            <div className="text-dolphin-1000 flex flex-col gap-2 rounded-lg bg-white p-8">
              <h3 className="h2 text-blueberry-700">Personal Finance +</h3>
              <h4 className="h4">$7.99/month</h4>
              <ul className="body-2 list-disc pl-8">
                <li>Everything in Personal Finance</li>
                <li>Unlimited monitored accounts</li>
                <li>Custom categories</li>
                <li>Transaction export</li>
                <li>Real-time transaction sync</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col gap-2 lg:items-center lg:text-center">
            <h4 className="h3">Achieve your financial goals with ATM!</h4>
            <h6 className="h5">
              Enjoy a free 30-day trial and cancel anytime!
            </h6>
            <InstantCashDisclosure className="mt-2 text-white" />
          </div>
        </div>
      </Container>
    </div>
  )
}
