import React from 'react'
import clsx from 'clsx'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Container } from '@ui/components/Container'
import { InstantCashDisclosure } from '@ui/components/Disclosure'

type Props = {
  className?: string
  children?: React.ReactNode
  amount?: number | null
}

export const MainBlockATMInstantCash = ({
  className,
  children,
  amount = 50,
}: Props) => {
  return (
    <div id="cash-advance" className={clsx('bg-white py-20', className)}>
      <Container>
        <div className="flex flex-col gap-8 lg:flex-row">
          <div className="flex flex-col gap-4 lg:w-1/2">
            <h2 className="font-montserrat h2">ATM Instant Cash™</h2>
            <ul className="list-disc space-y-6 pl-5">
              <li>
                <h3 className="h4 mb-2">Get up to ${amount}* Instantly</h3>
                <p className="body-2">
                  Access cash quickly when unexpected expenses arise. No
                  interest. No credit check. We&apos;re not a bank or a
                  lender—just a smarter way to manage your finances.
                </p>
              </li>
              <li>
                <h3 className="h4 mb-2">Avoid Overdraft Fees</h3>
                <p className="body-2">
                  Help shield yourself from costly overdraft fees with quick
                  access to emergency funds when you need them most.
                </p>
              </li>
              <li>
                <h3 className="h4 mb-2">Only $3.99/month</h3>
                <p className="body-2">
                  Unlock hassle-free cash access as a member. With just one low
                  monthly fee, you gain continuous access to cash without the
                  need for repeated applications. Membership puts financial
                  control at your fingertips.
                </p>
              </li>
            </ul>
            <InstantCashDisclosure className="mt-2" />
          </div>
          <div className="flex-auto lg:w-1/2">{children}</div>
        </div>
      </Container>
    </div>
  )
}

export const MainBlockHowToStartWithInstantCash = ({
  className,
  amount = 50,
}: Props) => {
  return (
    <div className={clsx('bg-off-white py-20', className)}>
      <Container>
        <h2 className="h2 font-montserrat mb-16 text-center">
          How to start with{' '}
          <span className="whitespace-pre">ATM Instant Cash™</span>
        </h2>
        <ol className="flex list-none flex-col gap-8 lg:flex-row">
          <li className="text-dolphin-1000 flex flex-col text-left lg:w-1/4">
            <span className="h3 text-blueberry-700 mb-2">1</span>
            <div>
              <h3 className="h3 mb-2">Apply in minutes</h3>
              <p className="body-2">
                See how much of an advance you qualify for with no credit check,
                just by linking the bank account where you receive income.
              </p>
            </div>
          </li>
          <li className="text-dolphin-1000 flex flex-col text-left lg:w-1/4">
            <span className="h3 text-blueberry-700 mb-2">2</span>
            <div>
              <h3 className="h3 mb-2">Request advances up to ${amount}*</h3>
              <p className="body-2">
                You have options when it comes to how much you take. We target
                an amount you can pay back without setting you back.
              </p>
            </div>
          </li>
          <li className="text-dolphin-1000 flex flex-col text-left lg:w-1/4">
            <span className="h3 text-blueberry-700 mb-2">3</span>
            <div>
              <h3 className="h3 mb-2">Cash out and spend</h3>
              <p className="body-2">
                Your cash advance will arrive to your card within seconds. Spend
                it however you like.
              </p>
            </div>
          </li>
          <li className="text-dolphin-1000 flex flex-col text-left lg:w-1/4">
            <span className="h3 text-blueberry-700 mb-2">4</span>
            <div>
              <h3 className="h3 mb-2">Repay and request another</h3>
              <p className="body-2">
                You agree to a settlement date when you take the advance*. We
                don't charge interest or late fees, so settling up won't come
                with surprises.
              </p>
            </div>
          </li>
        </ol>
        <InstantCashDisclosure className="mt-8" />
      </Container>
    </div>
  )
}

export const MainBlockHowATMMembersAreUsingInstantCash = ({
  className,
}: Props) => {
  return (
    <div className={clsx('bg-white py-20', className)}>
      <Container>
        <h2 className="h2 font-montserrat mx-auto mb-4 text-center lg:max-w-4xl">
          How members are using{' '}
          <span className="sm:whitespace-pre">ATM Instant Cash™</span>
        </h2>
        <h3 className="h5 mb-16 text-center">
          Where quick advances can make a difference.
        </h3>
        <ul className="mx-auto flex max-w-6xl flex-col items-center justify-between gap-8 lg:flex-row lg:gap-12">
          <li className="flex flex-col items-center justify-center gap-4">
            <img
              src="/svg/food-and-groceries.svg"
              alt="ATM Instant Cash™ Food &amp; Groceries"
            />
            Food &amp; Groceries
          </li>
          <li className="flex flex-col items-center justify-center gap-4">
            <img
              src="/svg/housing-and-utilities.svg"
              alt="ATM Instant Cash™ Housing &amp; Utilities"
            />
            Housing &amp; Utilities
          </li>
          <li className="flex flex-col items-center justify-center gap-4">
            <img
              src="/svg/auto-and-transport.svg"
              alt="ATM Instant Cash™ Auto &amp; Transportation"
            />
            Auto &amp; Transport
          </li>
          <li className="flex flex-col items-center justify-center gap-4">
            <img
              src="/svg/medical.svg"
              alt="ATM Instant Cash™ Medical &amp; Healthcare"
            />
            Medical &amp; Healthcare
          </li>
        </ul>
      </Container>
    </div>
  )
}

export const MainBlockFAQs = ({ className, amount = 50 }: Props) => {
  return (
    <div className={clsx('bg-white py-20', className)}>
      <Container>
        <h2 className="font-montserrat h2 mb-4 text-center">Got questions?</h2>
        <h3 className="h4 mb-8 text-center">
          We've got answers to help you out.
        </h3>
        <div className="mx-auto max-w-4xl">
          <Disclosure as="div" className="py-4">
            <DisclosureButton className="group flex w-full items-center justify-between text-left">
              <span className="text-blueberry-700 group-data-[hover]:text-blueberry-700/80 h5">
                How much can I request at a time?
              </span>
              <ChevronDownIcon className="size-5 fill-white/60 group-data-[open]:rotate-180 group-data-[hover]:fill-white/50" />
            </DisclosureButton>
            <DisclosurePanel
              transition
              className="text-dolphin-1000 body-2 mt-4 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              From $25{' '}
              {amount && amount > 25 ? `all the way to $${amount} ` : ' '}
              depending on your history and financial habits.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure as="div" className="py-4">
            <DisclosureButton className="group flex w-full items-center justify-between text-left">
              <span className="text-blueberry-700 group-data-[hover]:text-blueberry-700/80 h5">
                What determines my eligibility and advance amount?
              </span>
              <ChevronDownIcon className="size-5 fill-white/60 group-data-[open]:rotate-180 group-data-[hover]:fill-white/50" />
            </DisclosureButton>
            <DisclosurePanel
              transition
              className="text-dolphin-1000 body-2 mt-4 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              Eligibility and the advance amount are determined by a computer
              analysis of your primary linked bank account, using up to two
              years of historical income and expense data, along with other
              criteria. Your account must have a minimum of three consistent
              current direct deposits from the same source, a positive balance,
              and must meet other specific requirements.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure as="div" className="py-4">
            <DisclosureButton className="group flex w-full items-center justify-between text-left">
              <span className="text-blueberry-700 group-data-[hover]:text-blueberry-700/80 h5">
                Does Instant Cash impact my credit score or consider it?
              </span>
              <ChevronDownIcon className="size-5 fill-white/60 group-data-[open]:rotate-180 group-data-[hover]:fill-white/50" />
            </DisclosureButton>
            <DisclosurePanel
              transition
              className="text-dolphin-1000 body-2 mt-4 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              No, ATM Instant Cash™ does not impact your credit score and does
              not consider it. There is no credit check involved, and ATM.com
              does not report to credit bureaus.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure as="div" className="py-4">
            <DisclosureButton className="group flex w-full items-center justify-between text-left">
              <span className="text-blueberry-700 group-data-[hover]:text-blueberry-700/80 h5">
                When do I repay my advance?
              </span>
              <ChevronDownIcon className="size-5 fill-white/60 group-data-[open]:rotate-180 group-data-[hover]:fill-white/50" />
            </DisclosureButton>
            <DisclosurePanel
              transition
              className="text-dolphin-1000 body-2 mt-4 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              You repay your advance on the date of your next regularly
              scheduled direct deposit into your linked bank account. The
              repayment is automatically deducted or you can pay it back early
              without penalty.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure as="div" className="py-4">
            <DisclosureButton className="group flex w-full items-center justify-between text-left">
              <span className="text-blueberry-700 group-data-[hover]:text-blueberry-700/80 h5">
                Do I need to be a full-time employee?
              </span>
              <ChevronDownIcon className="size-5 fill-white/60 group-data-[open]:rotate-180 group-data-[hover]:fill-white/50" />
            </DisclosureButton>
            <DisclosurePanel
              transition
              className="text-dolphin-1000 body-2 mt-4 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              No, there is no requirement to be a full-time employee. However,
              you must have a bank account with consistent direct deposits from
              the same source to be eligible.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure as="div" className="py-4">
            <DisclosureButton className="group flex w-full items-center justify-between text-left">
              <span className="text-blueberry-700 group-data-[hover]:text-blueberry-700/80 h5">
                How long does it take to receive my advance?
              </span>
              <ChevronDownIcon className="size-5 fill-white/60 group-data-[open]:rotate-180 group-data-[hover]:fill-white/50" />
            </DisclosureButton>
            <DisclosurePanel
              transition
              className="text-dolphin-1000 body-2 mt-4 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              You can typically receive your advance within seconds after the
              request is approved, regardless of business hours or holidays.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure as="div" className="py-4">
            <DisclosureButton className="group flex w-full items-center justify-between text-left">
              <span className="text-blueberry-700 group-data-[hover]:text-blueberry-700/80 h5">
                How long does it take to get approved?
              </span>
              <ChevronDownIcon className="size-5 fill-white/60 group-data-[open]:rotate-180 group-data-[hover]:fill-white/50" />
            </DisclosureButton>
            <DisclosurePanel
              transition
              className="text-dolphin-1000 body-2 mt-4 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              Approval time is variable and determined by the time it takes to
              analyze your account and ensure eligibility criteria are met.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure as="div" className="py-4">
            <DisclosureButton className="group flex w-full items-center justify-between text-left">
              <span className="text-blueberry-700 group-data-[hover]:text-blueberry-700/80 h5">
                What if I’m not eligible?
              </span>
              <ChevronDownIcon className="size-5 fill-white/60 group-data-[open]:rotate-180 group-data-[hover]:fill-white/50" />
            </DisclosureButton>
            <DisclosurePanel
              transition
              className="text-dolphin-1000 body-2 mt-4 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              If you are not eligible, your application will be declined, but
              you may re-apply after 30 days from the date of the decline
              notice.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure as="div" className="py-4">
            <DisclosureButton className="group flex w-full items-center justify-between text-left">
              <span className="text-blueberry-700 group-data-[hover]:text-blueberry-700/80 h5">
                How do you help break debt cycles?
              </span>
              <ChevronDownIcon className="size-5 fill-white/60 group-data-[open]:rotate-180 group-data-[hover]:fill-white/50" />
            </DisclosureButton>
            <DisclosurePanel
              transition
              className="text-dolphin-1000 body-2 mt-4 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              ATM Instant Cash™ helps break debt cycles by offering
              non-recourse cash advances that do not involve high-interest loans
              or credit checks. This allows you to manage emergency expenses and
              avoid overdraft fees without incurring additional debt.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure as="div" className="py-4">
            <DisclosureButton className="group flex w-full items-center justify-between text-left">
              <span className="text-blueberry-700 group-data-[hover]:text-blueberry-700/80 h5">
                Are there any fees?
              </span>
              <ChevronDownIcon className="size-5 fill-white/60 group-data-[open]:rotate-180 group-data-[hover]:fill-white/50" />
            </DisclosureButton>
            <DisclosurePanel
              transition
              className="text-dolphin-1000 body-2 mt-4 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              Yes, there are fees associated with ATM Instant Cash™. The
              Transaction Fee varies depending on the amount of the advance,
              ranging from $5 to 5% of the advance amount. Additionally, there
              is a $3.99 monthly Membership Fee for access to ATM.com’s Personal
              Finance tools, which includes ATM Instant Cash™.
            </DisclosurePanel>
          </Disclosure>
        </div>
      </Container>
    </div>
  )
}

export const MainBlockWavyOffWhite = ({ className }: Props) => {
  return (
    <>
      <div className={clsx('main-block-wavy-off-white', className)}></div>
      <style jsx>{`
        .main-block-wavy-off-white {
          background-color: $off-white;
          background-image: url('/svg/vector-white-top.svg');
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          z-index: -2;
          aspect-ratio: 1440 / 273;
          width: 100%;
        }
      `}</style>
    </>
  )
}

export const MainBlockWavyWhite = ({ className }: Props) => {
  return (
    <>
      <div className={clsx('main-block-wavy-white', className)}></div>
      <style jsx>{`
        .main-block-wavy-white {
          background-color: white;
          background-image: url('/svg/vector-off-white-top.svg');
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          z-index: -2;
          aspect-ratio: 1440 / 273;
          width: 100%;
        }
      `}</style>
    </>
  )
}

export const MainBlockPersonalFinanceScreens = ({ className }: Props) => {
  return (
    <>
      <div
        className={clsx('main-block-personal-finance-screens', className)}
      ></div>
      <style jsx>{`
        .main-block-personal-finance-screens {
          background-color: $blueberry-200;
          background-image: url('/img/personal-finance-screens-400-v2@3x.jpg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          aspect-ratio: 1442 / 680;
          width: 100%;
        }
      `}</style>
    </>
  )
}
