import clsx from 'clsx'
import { Container } from '@ui/components/Container'
import {
  PressLinkList,
  type PressLinkListProps,
} from '@ui/main-blocks/press-link-list'

type Props = {
  className?: string
}

const media: Array<PressLinkListProps> = [
  {
    title:
      'Serial fintech founder raises $20M for Ant Money to make micro-investing even more accessible',
    description:
      "Now as 2021 comes to a close, Cruttenden's latest fintech, Ant Money, is announcing it has raised $20 million in funding (a mix of previously unannounced seed and Series A capital) and acquired Blast via a stock-for-stock merger.",
    link: 'https://techcrunch.com/2021/12/22/serial-fintech-founder-raises-20m-for-ant-money-to-make-investing-even-more-accessible/',
    date: 'December 22, 2021',
    publication: 'TechCrunch',
    img: '/img/press/publications/techcrunch.png',
  },
  {
    title:
      'Fintech Platform Ant Money Secures $20M, Merges With Investing App Blast',
    description:
      "Following the merger, Ant Money's platform now encompasses three apps to help users build investments.",
    link: 'https://www.builtinla.com/2021/12/22/ant-money-raises-20m-acquires-blast-hiring',
    date: 'December 22, 2021',
    publication: 'Built In Los Angeles',
    img: '/img/press/publications/builtinla.png',
  },
  {
    title:
      'SteelBridge Labs Announces Newest Company to Join the Incubator, ATM.com',
    description:
      'SteelBridge Labs is excited to welcome ATM.com to its portfolio. The investment in ATM.com continues their focus on supporting early-stage,...',
    link: 'https://www.digitaljournal.com/pr/steelbridge-labs-announces-newest-company-to-join-the-incubator-atm-com',
    date: 'September 15, 2021',
    publication: 'Digital Journal',
    img: '/img/press/publications/digital-journal.png',
  },
  {
    title: 'SteelBridge stakes fintech startup launched by Acorns founder',
    description:
      "SteelBridge Labs has invested in Acorns founder's new fintech start up. Timothy Haluszczak, co-founder and partner of the Strip District-based...",
    link: 'https://www.bizjournals.com/pittsburgh/news/2021/09/10/steelbridge-stakes-fintech-startup.html',
    date: 'September 10, 2021',
    publication: 'Pittsburgh Business Times',
    img: '/img/press/publications/pbt.png',
  },
  {
    title: 'New Cruttenden Apps Tackle Micro Investing',
    description:
      'Two intertwined Newport Beach-based companies that have launched new products in the past month are looking to become...',
    link: 'https://www.ocbj.com/news/2020/dec/21/new-cruttenden-apps-tackle-micro-investing/',
    date: 'December 21, 2020',
    publication: 'Orange County Business Journal',
    img: '/img/press/publications/ocbj.png',
  },
  {
    title: 'Cruttenden Startup Pays Consumers for Online Data',
    description:
      'For years, people have been giving data to Facebook, Google, and the like, without any cash payment in return. That was understandable...',
    link: 'https://www.ocbj.com/news/2019/mar/04/cruttenden-startup-pays-consumers-online-data/',
    date: 'March 4, 2019',
    publication: 'Orange County Business Journal',
    img: '/img/press/publications/ocbj.png',
  },
  {
    title:
      'Local Financial Guru Co-Launches Startup to Help People Control and Monetize Their Personal Data',
    description:
      'For years, people have been giving data to Facebook, Google, and the like, without any cash payment in return. That was understandable...',
    link: 'https://inter-tech-ion.tech/news-local-financial-guru-co-launches-startup-to-help-people-control-and-monetize-their-personal-data/',
    date: 'February 15, 2019',
    publication: 'inter-TECH-ion™',
    img: '/img/press/publications/intertechion.png',
  },
]

export const MainBlockPressMediaCoverage = ({ className }: Props) => {
  return (
    <div className={clsx('bg-white py-20', className)}>
      <Container>
        <div className="flex flex-col items-center gap-8">
          <h2 className="font-montserrat h2 w-full text-left">
            Media Coverage
          </h2>
          {PressLinkList(media)}
        </div>
      </Container>
    </div>
  )
}
