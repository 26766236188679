import clsx from 'clsx'
import { Container } from '@ui/components/Container'
import {
  PressLinkList,
  type PressLinkListProps,
} from '@ui/main-blocks/press-link-list'

type Props = {
  className?: string
}

const news: Array<PressLinkListProps> = [
  {
    title:
      'ATM.com, Inc. Announces the Acquisition of its Learn & Earn App by Junior Achievement USA',
    description:
      'ATM.com, Inc. today announced that its Learn & Earn app has been acquired by Junior Achievement USA. The app, developed by ATM’s Blast subsidiary in cooperation with JA, was launched in 2020 with an agreement to offer it through the JA network of over 200,000 classrooms and after-school locations. By using the app, students can complete short courses to earn real dollars that are then invested in stock market portfolios in their names. The transaction included the internet domain learnandearn.com.',
    link: 'https://world.einnews.com/pr_news/671493578/atm-com-inc-announces-the-acquisition-of-its-learn-earn-app-by-junior-achievement-usa',
    date: 'November 29, 2023',
  },
  {
    title:
      'Ant Money Closes Series A Funding Round and Completes Merger with Blast',
    description:
      "Ant Money, the embedded finance platform, today announced it has raised $20 million in a Series A financing led by Franklin Templeton's Franklin Venture Partners, Walter Cruttenden, RX3 Ventures, SteelBridge Laboratories, and Steelpoint Capital Partners. In conjunction with the merger, Ant Money also finalized the acquisition of Blast via a stock-for-stock merger. The move increases the number of apps under the Ant Money umbrella, from one to three - ATM, Blast, and Learn & Earn - all of which allow users to earn money that goes to build investment accounts.",
    link: 'https://www.accesswire.com/678734/Ant-Money-Closes-Series-A-Funding-Round-and-Completes-Merger-with-Blast',
    date: 'December 22, 2021',
  },
  {
    title:
      "ATM.com Creates “Data Dividends” in Response to Governor Newsom's Call",
    description:
      'Ant Transaction Machines, website ATM.com, has launched a new service to help people get paid for their own data...',
    link: 'https://www.businesswire.com/news/home/20190214005271/en/ATM.com-Creates-%E2%80%9CData-Dividends%E2%80%9D-in-Response-to-Governor-Newsom%E2%80%99s-Call',
    date: 'February 14, 2019',
  },
]

export const MainBlockPressCompanyNews = ({ className }: Props) => {
  return (
    <div className={clsx('bg-white py-20', className)}>
      <Container>
        <div className="flex flex-col items-center gap-8">
          <h2 className="font-montserrat h2 w-full text-left">Company News</h2>
          {PressLinkList(news)}
        </div>
      </Container>
    </div>
  )
}
